import store from '../store';

export const Types = {
    SELECT_TOKEN: 'tokens/select_token',
    UNSELECT_TOKEN: 'tokens/unselect_token',
};
export const selectToken = (token) => {
    store.dispatch(createSelectToken(token))
}

export const unselectToken = () => {
    store.dispatch(createUnselectToken())
}

export const createSelectToken = (token) => ({
    type: Types.SELECT_TOKEN,
    payload: {
        token: token
    }
})

export const createUnselectToken = (token) => ({
    type: Types.UNSELECT_TOKEN,
})
