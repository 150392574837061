import React, { useState } from 'react';
import c1 from '../../assets/gen2/3934.png';
import c2 from '../../assets/gen2/2_1.png';
import c3 from '../../assets/gen2/2383.png';
import c6 from '../../assets/gen2/6_1.png';
import c8 from '../../assets/gen2/8_1.png';

function About() {

    return (
        <div className="w-full h-full flex bg-aboutbg pt-20 pb-20 relative flex-col">
            <span id="about" className="absolute left-0 top-0"></span>
            <div className="flex flex-col relative max-w-5xl pb-10 justify-center mx-auto px-10 lg:px-0 md:px-20 ">
                <div className="w-full border-b-2 border-white leading-9 pb-4 md:pb-10">
                    <h1 className="uppercase text-center w-full font-face-ultra text-aboutbg text-3xl sm:text-4xl italic md:text-5xl lgmd:text-6xl lg:text-7xl" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'white' }}>About</h1>
                </div>
                <p className="px-0 pt-8 about-description-text text-white text-xl font-helvetica text-center tracking-wider">
                    CASSETS is a unique project bringing music artists
                    into the Solana NFT space. CASSETS started life as 1000 unique,
                    collectable pixel-art NFT's. <br></br><br></br>After a successful launch and sellout mint,
                    the 2 devs quit their jobs and are now focusing full time on the growth
                    of CASSETS. <br></br><br></br>In a short space of time we have already secured partnerships with well known music artists,
                    successfully airdropped uniquely generated music NFT's to
                    holders and now have a professional design/animation studio creating our
                    Gen2 3D hyper real CASSETS. We have big future plans including a fully
                    fledged platform for artists to release their original music into the NFT space!
                </p>
            </div>
            <div className="hidden lg:flex w-full flex-row space-x-7 lg:space-x-3 pt-10 px-5 lg:px-10 justify-center overflow-hidden">
                <img src={c1} className="hidden lg:inline-block lg:w-1/5" ></img>
                <img src={c2} className="hidden lg:inline-block lg:w-1/5"></img>
                <img src={c3} className="hidden lg:inline-block lg:w-1/5"></img>
                <img src={c6} className="hidden lg:inline-block lg:w-1/5" ></img>
                <img src={c8} className="hidden lg:inline-block lg:w-1/5" ></img>
            </div>

            <div className="hidden lgmd:flex w-full lg:hidden flex-row space-x-2 lg:space-x-14 pt-20 px-5 lg:px-14 justify-center overflow-hidden">
                <img src={c1} className="hidden lgmd:inline-block lgmd:w-1/4" ></img>
                <img src={c2} className="hidden lgmd:inline-block lgmd:w-1/4"></img>
                <img src={c3} className="hidden lgmd:inline-block lgmd:w-1/4"></img>
                <img src={c6} className="hidden lgmd:inline-block lgmd:w-1/4" ></img>
            </div>
            <div className="hidden md:flex lgmd:hidden lg:hidden w-full flex-row space-x-3 lg:space-x-14 pt-20 px-5 lg:px-14 justify-center overflow-hidden">
                <img src={c1} className="hidden md:inline-block md:w-1/3" ></img>
                <img src={c2} className="hidden md:inline-block md:w-1/3"></img>
                <img src={c3} className="hidden md:inline-block md:w-1/3"></img>
            </div>
            <div className="hidden sm:flex md:hidden lgmd:hidden lg:hidden w-full flex-row space-x-5 lg:space-x-14 pt-20 px-5 lg:px-14 justify-center overflow-hidden">
                <img src={c1} className="hidden sm:inline-block sm:w-1/2" ></img>
                <img src={c2} className="hidden sm:inline-block sm:w-1/2"></img>
            </div>
            <div className="hidden xs:flex sm:hidden md:hidden lgmd:hidden lg:hidden w-full flex-row space-x-5 lg:space-x-14 pt-10 pb-10 px-5 lg:px-14 justify-center overflow-hidden">
                <img src={c1} className="hidden xs:inline-block xs:w-full" ></img>
            </div>
        </div>
    );

}

export default About;