
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CANDY_MACHINES } from "../consts/consts";
import { fetchTokens, searchTokens } from "../actions/tokens";
import Gallery from "./Gallery";
import SearchBar from "./Searchbar";
import { Modal } from "@mui/material";
import NavBar from "./commmon/Navbar";

function GalleryTabs(props) {
    const [openTab, setOpenTab] = React.useState(1);
    const [searchPhrase, setSearchPhrase] = React.useState('');
    const [hasMore, setHasMore] = React.useState(true)

    const handleSearch = (e) => {
        e.preventDefault();
        let searchPhrase = e.target.value;
        setSearchPhrase(searchPhrase)

        if (searchPhrase !== "") {
            props.searchTokens(openTab == 1 ? CANDY_MACHINES.GEN_1_PNG : CANDY_MACHINES.GEN_2_AUDIO, searchPhrase)
            setHasMore(true)
        } else {
            props.fetchTokens(openTab == 1 ? CANDY_MACHINES.GEN_1_PNG : CANDY_MACHINES.GEN_2_AUDIO)
            setHasMore(false)
        }
    }
    return (
        <div>
            <NavBar />
        <div className=" flex flex-wrap bg-gray text-black w-full italic ">
            <div className="w-full ">
                <ul
                    className="mt-40 mx-10 md:grid md:grid-cols-4 bg-offwhite md:grid-flow-col md:mx-32 my-10 py-10 px-10 font-face-ultra"
                    role="tablist"
                >
                    <li className={" mr-2 col-span-1 text-center mb-4 " + (openTab === 1 ? 'bg-black' : 'bg-offwhite')}>
                        <a
                            className={"text-base font-bold uppercase py-3 block leading-normal sm:text-base " +
                                (openTab == 1 ? 'text-white bg-' + 'turq' + '-600' : 'text-' + 'turq' + '-600 bg-white')}
                            onClick={e => {
                                e.preventDefault();
                                setOpenTab(1);
                                if (searchPhrase != "") {
                                    props.searchTokens(CANDY_MACHINES.GEN_1_PNG, searchPhrase)
                                    setHasMore(true)
                                } else {
                                    props.fetchTokens(CANDY_MACHINES.GEN_1_PNG)
                                }
                            }}
                            data-toggle="tab" href="#link1" role="tablist">
                            CASSET
                        </a>
                    </li>
                    <li className={" mr-2 col-span-1 text-center mb-4 " + (openTab === 2 ? 'bg-black' : 'bg-offwhite')}>
                        <a className={"text-sm font-bold uppercase py-3 block leading-normal sm:text-base " +
                            (openTab == 2 ? 'text-white bg-' + 'turq' + '-600' : 'text-' + 'turq' + '-600 bg-white')}
                            onClick={e => {
                                e.preventDefault();
                                setOpenTab(2);
                                if (searchPhrase != "") {
                                    props.searchTokens(CANDY_MACHINES.GEN_2_AUDIO, searchPhrase)
                                    setHasMore(true)
                                } else {
                                    props.fetchTokens(CANDY_MACHINES.GEN_2_AUDIO)
                                }
                            }}
                            data-toggle="tab" href="#link2" role="tablist">
                            AUDIO
                        </a>
                    </li>
                    <li className="-mb-px mr-2 last:mr-0 col-start-4 text-center uppercase">
                        <SearchBar handleSearch={handleSearch}></SearchBar>
                    </li>
                </ul>
                {/* <div className=" mx-auto h-min justify-self-center justify-center content-center bg-white lg:mx-32 sm:px-4 md:px-4 sm:px-0 ">
                        <p className="w-full p-10">This was our first collection! Generation 1 was a proof of concept, an ode to the classic style of pixel art NFTs</p>
                    </div> */}
                <div className="relative flex flex-col flex-wrap w-full justify-center content-center">
                    <div className="h-screen flex flew-wrap w-full justify-self-center justify-center content-center" id="link1">
                        <Gallery searchPhrase={searchPhrase} tab={openTab} candyMachine={openTab == 1 ? CANDY_MACHINES.GEN_1_PNG : CANDY_MACHINES.GEN_2_AUDIO} ></Gallery>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};
export default connect((tokenReducer) => (tokenReducer), {
    fetchTokens,
    searchTokens
})(GalleryTabs);