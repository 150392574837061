function Rewards() {
    return (
        <div className="w-full h-full flex bg-aboutbg pt-20 pb-20 relative flex-col">
            <span id="launch" className="absolute left-0 top-0"></span>
            <div className="flex flex-col relative max-w-5xl justify-center mx-auto px-10 lg:px-0 md:px-20 ">
                <div className="w-full border-b-2 border-white leading-9 pb-4 md:pb-10">
                    <h1 className="uppercase text-center w-full font-face-ultra text-aboutbg text-3xl sm:text-4xl italic md:text-5xl lgmd:text-6xl lg:text-7xl" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'white' }}>GEN-2 LAUNCH</h1>
                </div>
                <div className="w-full h-full py-20 ">
                    <h1 className="uppercase text-center font-face-ultra text-2xl italic sm:text-3xl md:text-4xl text-white lg:text-5xl italic">01. GEN-2 AIRDROP</h1>
                    <p className="px-0 pt-8 about-description-text text-white text-lg md:text-xl font-helvetica text-center tracking-wider">
                        Airdrop for those holding matches will take place on 15th Dec 10pm UTC
                        (snapshot will be taken on 13th Dec 10pm UTC. Make sure you have your matches unlisted)
                    </p>
                </div>
                <div className="w-full h-full pb-20 ">
                    <h1 className="uppercase text-center font-face-ultra text-2xl italic sm:text-3xl md:text-4xl text-white lg:text-5xl italic">02. GEN-2 PRESALE</h1>
                    <p className="px-0 pt-8 about-description-text text-white text-lg md:text-xl font-helvetica text-center tracking-wider">
                        Presale mint for those holding a Gen-1 CASSET will take place on 16th Dec 10pm UTC with a mint price of 0.3 SOL
                    </p>
                </div>
                <div className="w-full h-full pb-20 ">
                    <h1 className="uppercase text-center font-face-ultra text-2xl italic sm:text-3xl md:text-4xl text-white lg:text-5xl italic">03. BATCH 1</h1>
                    <p className="px-0 pt-8 about-description-text text-white text-lg md:text-xl font-helvetica text-center tracking-wider">
                    The first batch public mint will take place on 17th Dec 10pm UTC with a mint price of 0.6 SOL
                    </p>
                </div>
                <div className="w-full h-full ">
                    <h1 className="uppercase text-center font-face-ultra text-2xl italic sm:text-3xl md:text-4xl text-white lg:text-5xl italic">04. REMINAING BATCHES</h1>
                    <p className="px-0 pt-8 about-description-text text-white text-lg md:text-xl font-helvetica text-center tracking-wider">
                    After the sellout of the 1st batch, the 2nd batch will be launched 2 days later. And the 3rd batch 2 days after sellout of the 2nd and so on. Prices will slightly increase with each batch
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Rewards;