import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, query, limit, getDocs, orderBy, startAfter, startAt, endAt, where } from "@firebase/firestore";

const firebaseApp = initializeApp({
    apiKey: "AIzaSyD8JrTcPg8EYvtP_8C53AGg3OLmVmYxXp4",
    authDomain: "cassets-bbb5a.firebaseapp.com",
    projectId: "cassets-bbb5a",
});

const db = getFirestore();

let lastDocument;

export const getTokenMetaInfoCollection = async (candyMachine, end) => {
    try {
        // Create a reference to the candymachine's collection collection
        const tokensQuery = collection(db, candyMachine);
        // Create a query against the collection.
        const q = query(tokensQuery, orderBy("tokens.arweave.name"), limit(end));
        let result = []
        let docs = await getDocs(q)
        docs.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            if (doc.data() != undefined) {
                result.push(doc.data())
            }
            lastDocument = doc
        })

        console.log(lastDocument)
        console.log("LAST DOCUMENT")
        return result
    } catch (e) {
        console.log(e)
    }
}


export const getTokenMetaInfoCollectionPaginated = async (candyMachine) => {
    try {

        console.log(lastDocument)
        // Get the last visible document    
        // Construct a new query starting at this document,
        // get the next 25 cities.
        const q = query(collection(db, candyMachine),
            orderBy("tokens.arweave.name"),
            startAfter(lastDocument),
            limit(25));
        let result = []
        let docs = await getDocs(q)
        docs.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots    
            if (doc.data() != undefined) {
                result.push(doc.data())
            }
            lastDocument = doc
        })
        return result

    } catch (e) {
        console.log(e)
    }
}

export const getSearchResults = async (searchPhrase, candyMachine) => {
    console.log("SEARCHING ", candyMachine)
    try {
        const citiesRef = collection(db, candyMachine);
        const q = query(citiesRef);
        let docs = await getDocs(q)
        let result = []

        await docs.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots    
            if(doc.id.includes(searchPhrase)){
                result.push(doc.data())
            }
        })
        return result
    } catch (error) {   
        console.log(error)
    }
}