import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { CANDY_MACHINES } from '../consts/consts';
import { selectToken, unselectToken } from '../actions/modal';

function GalleryCard({ token, candyMachine, modalReducer }) {

    const [show, setShow] = useState(false);

    const selectDeselectModal =() =>{
        if(modalReducer.token == null){
            document.body.style.overflow = 'hidden';
            selectToken(token)
        }else{
            document.body.style.overflow = 'unset';
            unselectToken()
        }
    }

    let displayItem;
    let title;

    if (candyMachine == CANDY_MACHINES.GEN_1_PNG && token != undefined) {
        displayItem = <img className="flex-start w-full h-4/5" src={token.tokens.arweave.image} />;
        title = <h2 style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'black' }} className=" text-white">CASSET</h2>
    }

    else if (candyMachine == CANDY_MACHINES.GEN_2_AUDIO && token != undefined) {
        displayItem = <video className="border-1 border-gray-50 w-full h-4/5 " poster={token.tokens.arweave.image} preload="metadata" controls="controls"><source src={token.tokens.arweave.animation_url} type='video/mp4'></source></video>;
        title = <h2 style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'black' }} className=" text-white">AUDIO </h2>
    }


    if (token != undefined) {
        return (
            <div className="my-1 w-full px-10 py-2 px-4 sm:px-4 sm:w-1/2 sm:mx-0 md:w-1/3 md:px-4 lg:px-4 lg:my-4 lg:mx-1/4 lg:w-1/4 xl:w-1/5 text-sm font-face-ultra italic">
                <a href="#">
                <article onClick={selectDeselectModal} className=" p-6 overflow-hidden bg-offwhite hover:xxx hover:scale-105 transition-all transform duration-500">

                    {displayItem}

                    <div className="h-1/5 pt-5 flex justify-between uppercase w-full">
                        {title}
                        <h2 >#{token.tokens.arweave.name.match(/-?\d+\.?\d*/)}</h2>
                    </div>
                </article>
                </a>
            </div>
        );
    }
    else {
        return (
            <div className="my-1 w-full px-10 py-2 px-4 sm:px-4 sm:w-1/2 sm:mx-0 md:w-1/3 md:px-4 lg:px-4 lg:my-4 lg:mx-1/4 lg:w-1/4 xl:w-1/5 text-sm font-face-ultra italic">
                <article onClick={selectDeselectModal} className=" p-6 overflow-hidden bg-offwhite hover:xxx hover:scale-105 transition-all transform duration-500">    
                    <div className="h-1/5 pt-5 flex justify-between uppercase w-full">
                        Casset
                        <h2 >#1</h2>
                    </div>
                </article>
            </div>
        )
    }

}

export default connect((modalReducer) => (modalReducer), {
    selectToken,
    unselectToken
})(GalleryCard)