
import { Types, fil } from "../actions/tokens";
import store from "../store";

const INITIAL_STATE = {
    tokens: [],
    loading: false,
    candyMachine: '',
    allTokens:{}
};

/*
*  Token reducer that handles all token related actions
*/
export default function tokenReducer(state = INITIAL_STATE, action) {
    switch (action.type) {


        case Types.GET_TOKENS_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case Types.GET_TOKENS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        /** Gen1 Token Actions */
        case Types.GET_TOKENS_SUCCESS: {
            console.log('GET TOKENS SUCCESS');
            console.log(action.payload.tokens);
            return {
                ...state,
                loading: false,
                tokens: state.tokens.concat(action.payload.tokens)
            }
        }

        case Types.CLEAR_TOKENS:{
            return {
                ...state,
                loading: false,
                tokens: []
            }
        }

        case Types.GET_ALL_TOKENS_SUCCESS_GEN_1:{
            return {
                ...state,
                allTokens:{
                    ...state.allTokens,
                    gen1: action.payload.gen1
                }
            }
        }

        case Types.GET_ALL_TOKENS_SUCCESS_GEN_2:{
            return {
                ...state,
                allTokens:{
                    ...state.allTokens,
                    gen2: action.payload.gen2
                }
            }
        }

        default: 
            return state
    }
}
