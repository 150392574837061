import React, { useState } from 'react';
import { AnimatedOnScroll } from "react-animated-css-onscroll";

function Roadman(props) {

    return (
        <section className="bg-aboutbg overflow-hidden relative font-helvetica pb-20 pt-20">
            <span id="roadmap" className="absolute left-0 top-0"></span>
            <div className="w-full lg:px-0 px-10 md:px-20 max-w-5xl mx-auto">
                <div className="w-full border-b-2 border-white leading-9 pb-4 md:pb-10">
                    <h1 className="uppercase text-center w-full font-face-ultra text-aboutbg text-3xl sm:text-4xl italic md:text-5xl lgmd:text-6xl lg:text-7xl" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'white' }}>Roadmap</h1>
                </div>
                <h2 className="text-xl text-white lg:mx-28 text-center pt-14 ">The roadmap outlines the path and goals of CASSETS, and where it is headed. We are dedicated to delivering value to the CASSETS holders, and rewarding their loyalty whilst also providing utility.</h2>
                <div className="w-full relative z-10 font-helvetica space-y-4 pt-8">
                    <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={800} isVisible={true}>
                        <div className="mt-8 w-full h-full mx-auto bg-roadmapbg flex flex-col md:flex-row ">
                            <div className="w-full lg:h-50 lg:mx-28 flex flex-col text-center md:text-left p-5 lg:m-5 md:p-4 space-y-2">
                                {/* <p className="text-2xl text-white font-face-ultra uppercase italic font-bold">01.</p> */}
                                <p class="text-2xl md:text-3xl text-white font-face-ultra uppercase italic font-bold"><span className="text-roadmapbg" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'white' }}>01.</span> Design and Creation</p>
                                <p class="text-lg md:text-xl w-full leading-relaxed text-white font-normal pt-6">Design and create 1000 unique CASSETS NFT's of varying rarity. Incorporating a simplistic traditional NFT pixel-art design with a bold period correct colour palette. </p>

                            </div>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={800} isVisible={true}>
                        <div className="mt-8 w-full mx-auto bg-roadmapbg flex flex-col md:flex-row ">
                            <div className="w-full lg:h-50 lg:mx-28 flex flex-col text-center md:text-left p-5 lg:m-5 md:p-4 space-y-2">
                                <p class="text-2xl md:text-3xl text-white font-face-ultra uppercase italic font-bold"><span className="text-roadmapbg" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'white' }}>02.</span> Marketing and Socials</p>
                                <p class="text-lg md:text-xl leading-relaxed text-white font-normal pt-6">Deploy CASSETS official website, Discord and Twitter. Start building the CASSETS brand and attract an audience of NFT and music enthusiasts. Run numerous Twitter and Discord giveaways and promotions. </p>

                            </div>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={800} isVisible={true}>
                        <div className="mt-8 w-full mx-auto bg-roadmapbg flex flex-col md:flex-row ">
                            <div className="w-full lg:h-50 lg:mx-28 flex flex-col text-center md:text-left p-5 lg:m-5 md:p-4 space-y-2">
                                <p class="text-2xl md:text-3xl text-white font-face-ultra uppercase italic font-bold"><span className="text-roadmapbg" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'white' }}>03.</span> Mint and Launch</p>
                                <p class="text-lg md:text-xl leading-relaxed text-white font-normal pt-6">Open mint through the CASSETS official website. Once sold out, get CASSETS listed on multiple Solana marketplaces.</p>

                            </div>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={800} isVisible={true}>
                        <div className="mt-8 w-full mx-auto bg-roadmapbg flex flex-col md:flex-row ">
                            <div className="w-full lg:h-50 lg:mx-28 flex flex-col text-center md:text-left p-5 lg:m-5 md:p-4 space-y-2">
                                <p class="text-2xl md:text-3xl text-white font-face-ultra uppercase italic font-bold"><span className="text-roadmapbg" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'white' }}>04.</span> CASSETS Audio</p>
                                <p class="text-lg md:text-xl leading-relaxed text-white font-normal pt-6">Generate 1000 unique Algorithmic "CASSETS Audio" samples. Samples are minted and airdropped to Gen1 CASSETS holders. CASSETS Audio collection added to marketplaces.</p>

                            </div>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={800} isVisible={true}>
                        <div className="mt-8 w-full mx-auto bg-roadmapbg flex flex-col md:flex-row ">
                            <div className="w-full lg:h-50 lg:mx-28 flex flex-col text-center md:text-left p-5 lg:m-5 md:p-4 space-y-2">
                                <p class="text-2xl md:text-3xl text-white font-face-ultra uppercase italic font-bold"><span className="text-roadmapbg" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'white' }}>05.</span> Generation 2</p>
                                <p class="text-lg md:text-xl leading-relaxed text-white font-normal pt-6">Partner with a professional design studio to create 3D hyper-real gen2 CASSETS whilst still retaining the vibe of the gen1.</p>

                            </div>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={800} isVisible={true}>
                        <div className="mt-8 w-full mx-auto bg-roadmapbg flex flex-col md:flex-row ">
                            <div className="w-full lg:h-50 lg:mx-28 flex flex-col text-center md:text-left p-5 lg:m-5 md:p-4 space-y-2">
                                <p class="text-2xl md:text-3xl text-white font-face-ultra uppercase italic font-bold"><span className="text-roadmapbg" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'white' }}>06.</span> Generation 2 Marketing</p>
                                <p class="text-lg md:text-xl leading-relaxed text-white font-normal pt-6">Overhaul of CASSETS website and socials. Run numerous giveaways and promotions across Twitter and Discord. Announce rewards for generation 1 holders</p>

                            </div>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={800} isVisible={true}>
                        <div className="mt-8 w-full mx-auto bg-roadmapbg flex flex-col md:flex-row ">
                            <div className="w-full lg:h-50 lg:mx-28 flex flex-col text-center md:text-left p-5 lg:m-5 md:p-4 space-y-2">
                                <p class=" text-2xl md:text-3xl text-white font-face-ultra uppercase italic font-bold"><span className="text-roadmapbg" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'white' }}>07.</span> Generation 2 Mint and Launch</p>
                                <p class=" text-lg md:text-xl leading-relaxed text-white font-normal pt-6">Open mint of gen2 NFT' through website. Once sold out, get gen2 CASSETS NFT's listed on Solana marketplaces</p>

                            </div>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={800} isVisible={true}>
                        <div className="mt-8 w-full mx-auto bg-roadmapbg flex flex-col md:flex-row ">
                            <div className="w-full lg:h-50 lg:mx-28 flex flex-col text-center md:text-left p-5 lg:m-5 md:p-4 space-y-2">
                                <p class=" text-2xl md:text-3xl text-white font-face-ultra uppercase italic font-bold"><span className="text-roadmapbg" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'white' }}>08.</span> Generation 2 Marketing</p>
                                <p class=" text-lg md:text-xl leading-relaxed text-white font-normal pt-6">Overhaul of CASSETS website and socials. Run numerous giveaways and promotions across Twitter and Discord. Announce rewards for generation 1 holders.</p>

                            </div>
                        </div>
                    </AnimatedOnScroll>
                    
                    <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={800} isVisible={true}>
                        <div className="mt-8 w-full mx-auto bg-roadmapbg flex flex-col md:flex-row ">
                            <div className="w-full lg:h-50 lg:mx-28 flex flex-col text-center md:text-left p-5 lg:m-5 md:p-4 space-y-2">
                                <p class=" text-2xl md:text-3xl text-white font-face-ultra uppercase italic font-bold"><span className="text-roadmapbg" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'white' }}>09.</span> Generation 2 Mint and Launch</p>
                                <p class=" text-lg md:text-xl leading-relaxed text-white font-normal pt-6">Airdrop to matching numbers holders, Presale for GEN-1 holders, then open mint of gen2 NFT' through website. Once sold out, get gen2 CASSETS NFT's listed on Solana marketplaces.
                                </p>
                            </div>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={800} isVisible={true}>
                        <div className="mt-8 w-full mx-auto bg-roadmapbg flex flex-col md:flex-row ">
                            <div className="w-full lg:h-50 lg:mx-28 flex flex-col text-center md:text-left p-5 lg:m-5 md:p-4 space-y-2">
                                <p class=" text-2xl md:text-3xl text-white font-face-ultra uppercase italic font-bold"><span className="text-roadmapbg" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'white' }}>10.</span> DAO DECISIONS</p>
                                <p class=" text-lg md:text-xl leading-relaxed text-white font-normal pt-6">Let the community decide where royalties can be best used and what future airdrops members would like to reward GEN-1/2 holders. 50% royalties allocated to DAO. Remaining 50% given to BOJET/POOTY/FIELDS</p>
                            </div>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={800} isVisible={true}>
                        <div className="mt-8 w-full mx-auto bg-roadmapbg flex flex-col md:flex-row ">
                            <div className="w-full lg:h-50 lg:mx-28 flex flex-col text-center md:text-left p-5 lg:m-5 md:p-4 space-y-2">
                                <p class=" text-2xl md:text-3xl text-white font-face-ultra uppercase italic font-bold"><span className="text-roadmapbg" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'white' }}>11.</span> HOLDERS AIRDROP</p>
                                <p class=" text-lg md:text-xl leading-relaxed text-white font-normal pt-6">Airdrop to GEN-1/2 holders.</p>
                            </div>
                        </div>
                    </AnimatedOnScroll>
                </div>
            </div>
        </section>
    );
}

export default Roadman;