import logo from '../../assets/logo_layer.png'

function Footer() {

    return (
        <div className="w-full bg-landingbg h-80">
            <div className="max-w-5xl mx-auto h-full align-middle items-center justify-center flex flex-row">
                <div className="h-1/2 items-center flex flex-col ">
                    <img className="w-1/4" src={logo}></img>
                    <div className="flex flex-row">
                    <a href="https://twitter.com/CassetsNFTs"
                                to="/Home"

                                className="w-1/2 nav-links"
                                target="_blank" >
                                <i className="fa text-black fa-3x fa-twitter"
                                ></i>
                            </a>
                    <a href="https://t.co/GcIvX08k1Q?amp=1"
                        to="/Home"
                        className="nav-links    "
                        target="_blank" >
                        <i className="fab text-black fa-discord fa-3x"
                        ></i>
                    </a>
                    </div>
                    <h1 className=" uppercase text-xs md:text-md text-center">©2021 CASSETS. All rights reserved.</h1>
                </div>
            </div>
        </div>
    );

}

export default Footer;