import React, { useState } from "react";
import { connect } from "react-redux";
import { fetchMoreTokens, fetchTokens, searchTokens } from '../actions/tokens';
import TokenCard from "./TokenCard";
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchBar from "./Searchbar";
import Modal from "./Modal"
const style = {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8
};
class Gallery extends React.Component {

    constructor(props) {
        super(props)
        this.fetchMoreData = this.fetchMoreData.bind(this);
    }

    state = {
        index: 20,
        hasMore: true,
    }
    componentDidMount() {
        this.props.fetchTokens(this.props.candyMachine)
    }

    fetchMoreData = () => {
        console.log("fetching more")
        if (this.props.searchPhrase) {
            return
        }
        this.props.fetchMoreTokens(this.props.candyMachine)
    };


    render() {
        if (this.props.tokenReducer.tokens !== undefined) {

            return (
                <div className="">
                    <Modal></Modal>
                    <InfiniteScroll
                        dataLength={this.props.tokenReducer.tokens.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.hasMore}
                        loader={<h4></h4>}
                        style={{ width: "100%"}}
                    >
                        <div className="flex justify-center text-black w-screen flex-wrap bg-gray lg:px-32 sm:px-4 sm:mx-0 md:px-4 sm:px-0">
                            {this.props.tokenReducer.tokens.map((i, index) => (
                                <TokenCard candyMachine={this.props.candyMachine} key={index} token={i} />
                            ))}
                        </div>

                    </InfiniteScroll>
                </div>
            )
        } else {
            return (
                <div>
                    LOADING
                </div>)
        }
    }
}


export default connect((tokenReducer) => (tokenReducer), {
    fetchMoreTokens,
    fetchTokens,
    searchTokens
})(Gallery);