import React from 'react';
// import "../../styles/Rarity.css"

import background from "../../assets/gen2_rarity/backgrounds_gen2.png"
import cassettes from "../../assets/gen2_rarity/cassettes_gen2.png"
import stickers from "../../assets/gen2_rarity/stickers_gen2.png"
import labels from "../../assets/gen2_rarity/wheels_gen2.png"
import sides from "../../assets/gen2_rarity/sides_gen2.png"
import NavBar from '../commmon/Navbar';

function Rarity2() {

    return (
        <div>
            <NavBar />
        <div className="overflow-hidden rarity-section text-black bg-white relative max-w-5xl w-full mx-auto font-face-ultra py-20 px-6 lg:px-0 md:py-20" id="rarity">
            <span id="rarity" className="absolute top-0 "></span>
            <div className="w-full border-b-2 leading-9 pb-4 md:pb-10 mt-20">
                <h1 className="uppercase text-center w-full font-face-ultra text-white text-3xl sm:text-4xl italic md:text-5xl lgmd:text-6xl lg:text-7xl" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'black' }}>Rarity Chart</h1>
            </div>
            <div className="text-2xl md:text-3xl lg:text-5xl text-center pt-20">
                <h1 className="uppercase font-face-ultra text-2xl italic sm:text-3xl text-center md:text-4xl lg:text-5xl">Backgrounds</h1>
                <img className="backgrounds-image" src={background}></img>
            </div>
            <div className="text-2xl md:text-3xl lg:text-5xl text-center pt-1">
                <h1 className="pt-10 uppercase font-face-ultra text-2xl italic sm:text-3xl text-center md:text-4xl lg:text-5xl">Casset</h1>
                <img className="backgrounds-image" src={cassettes}></img>
            </div>
            <div className="text-2xl md:text-3xl lg:text-5xl text-center pt-5">
                <h1 className="pt-10 uppercase font-face-ultra text-2xl italic sm:text-3xl text-center md:text-4xl lg:text-5xl">Stickers</h1>
                <img className="backgrounds-image" src={stickers}></img>
            </div>
            <div className="text-2xl md:text-3xl lg:text-5xl text-center pt-5">
                <h1 className="pt-10 uppercase font-face-ultra text-2xl italic sm:text-3xl text-center md:text-4xl lg:text-5xl">Wheels</h1>
                <img className="backgrounds-image" src={labels}></img>
            </div>
            <div className="text-2xl md:text-3xl lg:text-5xl text-center pt-5">
                <h1 className="pt-10 uppercase font-face-ultra text-2xl italic sm:text-3xl text-center md:text-4xl lg:text-5xl">Sides</h1>
                <img className="backgrounds-image" src={sides}></img>
            </div>
            <div className="text-2xl md:text-3xl lg:text-5xl text-center pt-5">
                <h1 className="pt-10 uppercase font-face-ultra text-2xl italic sm:text-3xl text-center md:text-4xl lg:text-5xl">Tracks</h1>
                <div className="grid grid-cols-3 w-full uppercase font-face-bahn mt-2 md:mt-10" >
                    <div className="col-span-1">
                        <h1 className="text-xl md:text-3xl">Common</h1>
                        <div className="text-lg md:text-xl mt-5">
                            <h2>Fields 1</h2>
                            <h2>Fields 2</h2>
                            <h2>Fields 3</h2>
                            <h2>Fields 4</h2>
                            <h2>Fields 5</h2>
                            <h2>Fields 6</h2>
                            <h2>Fields 7</h2>
                            <h2>Fields 8</h2>
                            <h2>Fields 9</h2>
                            <h2>Fields 10</h2>
                            <h2>Fields 11</h2>
                            <h2>Fields 12</h2>
                            <h2>Fields 13</h2>
                            <h2>Fields 14</h2>
                            <h2>Fields 15</h2>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <h1 className="text-xl md:text-3xl">Rare</h1>
                        <div className="text-lg md:text-xl mt-5">
                            <h2>Bojet 1</h2>
                            <h2>Bojet 2</h2>
                            <h2>Bojet 3</h2>
                            <h2>Bojet 4</h2>
                            <h2>Bojet 5</h2>
                            <h2>Bojet 6</h2>
                            <h2>Bojet 7</h2>
                            <h2>Bojet 8</h2>
                            <h2>Bojet 9</h2>
                            <h2>Bojet 10</h2>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <h1 className="text-xl md:text-3xl">Legendary</h1>
                        <div className="text-lg md:text-xl mt-5">
                            <h2>Cap a Feeling</h2>
                            <h2>Fly Away</h2>
                            <h2>Out of Time</h2>
                            <h2>Sixty Six</h2>
                            <h2>With Me</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );

}

export default Rarity2;