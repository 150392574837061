
import store from "../store";
import { Types  } from "../actions/modal";
const INITIAL_STATE = {
    token: null,
    showToken: false
};

/*
*  Token reducer that handles all token related actions
*/
export default function modalReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.SELECT_TOKEN: {
            return {
                ...state,
                token: action.payload,
                showToken: true
            }
        }
        case Types.UNSELECT_TOKEN: {
            return {
                ...state,
                token: null,
                showToken: false
            }
        }
        default: 
            return INITIAL_STATE
    }
}
