import { Grid } from '@mui/material';
import { grid } from '@mui/system';
import React from 'react';
import { CANDY_MACHINES } from '../consts/consts';
import { ReactComponent as YourSvg } from '../assets/dark-explorer-logo.2d910a55.svg'
import modalReducer from '../reducers/modal';
import { unselectToken } from '../actions/modal';
import { connect } from 'react-redux'


function Modal(props) {


    const handleOutsideClick = e => {
        console.log('handle outside!');
        document.body.style.overflow = 'unset';
        unselectToken();
        
        // if (!this.node.contains(e.target)) unselectToken();
    };

    if (props.modalReducer.showToken == false) {
        return <></>
    }
    else {
        let token = props.modalReducer.token.token
        let displayItem;
        let _desc;
        console.log(token)
        if (token != null && token.tokens.arweave.animation_url == undefined){
            displayItem = <img className="my-auto w-full" src={token.tokens.arweave.image} />;
            _desc = <p className="justify-center align-middle justify-items-center items-center ">This was our first collection! Generation 1 was a proof of concept, an ode to the classic style of pixel art NFTs</p>
        }
        else if (token != null && token.tokens.arweave.animation_url != undefined){
            displayItem = <video className=" w-full h-half" poster={token.tokens.arweave.image} preload="metadata" controls="controls">
                <source src={token.tokens.arweave.animation_url} type='video/mp4'></source>
            </video>;
            _desc = <p className="justify-center align-middle justify-items-center items-center ">This was our first airdrop for Generation 1 Casset holders. A collection of programatically generated audio tracks to get groovy with.</p>
        }

        return (
            <>
                {token != undefined ? (
                    <>
                        <div
                            className="text-black justify-center font-face-ultra italic uppercase items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                            onClick={() => handleOutsideClick()}
                        >
                            <div className="relativew-auto my-6 mx-auto max-w-3xl">
                                {/*content*/}
                                <div onClick={e => {
                                    // do not close modal if anything inside modal content is clicked
                                    e.stopPropagation();
                                }} className="border-0 bg-offwhite shadow-lg relative flex flex-col w-4/5 md:w-1/2 mx-auto md:w-full outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start h-4/5 md:full justify-between p-2 md:p-5 border-b border-solid border-blueGray-200 rounded-t">
                                        <div className="pt-2 md:h-1/5 md:pt-5 flex justify-between uppercase w-full">
                                            <h2 >{token.tokens.arweave.name.replace("/-?\d+\.?\d*/", "")}</h2>
                                            <h2 >{token.tokens.arweave.name.match(/-?\d+\.?\d*/)}</h2>
                                        </div>
                                    </div>
                                    {/*body*/}
                                    <div className="relative h-full p-6 flex flex-col items-center md:flex-row md:justify-between text-xl ">
                                        <div className="w-2/4 md:w-1/2 my-auto" >
                                            {displayItem}
                                        </div>
                                        <div className="w-full md:w-1/2 h-full flex flex-col md:grid md:grid-rows-3 justify-between p-5">

                                            {/* <div  className="h-36">

                                        </div> */}
                                            <div className="">
                                                <div className="grid grid-cols-2" >
                                                    <div className="text-sm md:text-lg text-left">Types</div>
                                                    <div className="text-sm md:text-lg text-right">Value </div>

                                                    {token.tokens.arweave.attributes.map(e => {
                                                        return < >
                                                            <div className="text-sm font-bold text-left font-sans" >{e.trait_type}</div>
                                                            <div className="text-sm text-right font-sans" >{e.value}</div>
                                                        </>
                                                    })}
                                                </div>
                                            </div>
                                            <div className="h-1/2 text-sm font-sans align-middle justify-items-center pt-5 md:pt-10 items-center normal-case flex flex-col place-items-center">
                                                {_desc}
                                            </div>
                                            <div className="flex flex-col justify-end pt-5 md:pt-0">
                                                <button className="bg-black h-1/2 md:h-1/2 w-full rounded-lg">

                                                    <a href={"https://explorer.solana.com/address/" + token.tokens.meta.mint} target="_blank">
                                                        <YourSvg className="content-center p-2 h-1/3 md:h-full w-full" />
                                                    </a>
                                                </button>
                                            </div>
                                        </div>


                                    </div>

                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-2 md:p-6 border-t border-solid border-blueGray-200 rounded-b">
                                        <button
                                            className=" bg-emerald-500 uppercase active:bg-emerald-600  text-xs md:text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => unselectToken()}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
            </>
        );
    }




} export default connect((modalReducer) => (modalReducer), {
    unselectToken
})(Modal);

// export default connect((tokenReducer) => (tokenReducer), {
//     fetchMoreTokens,
//     fetchTokens,
//     searchTokens
// })(Gallery);