import { getAllTokens } from "../../actions/tokens";
import { Component } from "react";
import { connect } from "react-redux";
class Startup extends Component {
    
    componentDidMount() {
        this.props.getAllTokens();
    }

    render(){
        return (<></>)
    }
}

export default connect((tokenReducer) => (tokenReducer), {
    getAllTokens,
})(Startup);