import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import '../../styles/NavBar.css';
import logo from '../../assets/logo.png'
import logoText from '../../assets/logo_layer.png'
import { Squash as Hamburger } from 'hamburger-react'

function NavBar() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    return (
        <>
            <nav className="navbar w-full flex flex-col lg:flex-row justify-space-between">
                
                <a href="/" className="absolute left-14 top-6 md:left-10 md:top-5">
                    <img src={logoText} style={{width: 'auto', height: '100px'}}></img>
                </a>
                <div className="nav-container mt-16 max-w-6xl">
                    <ul className={click ? "nav-menu active font-face-ultra italic" : "nav-menu font-face-ultra italic flex-grow "}>
                        <li className="nav-item ">
                            <Link
                                to="/home#home"
                                className="nav-links"
                                onClick={handleClick}>
                                HOME
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/gallery"
                                className="nav-links external"
                                onClick={handleClick}>
                                GALLERY
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/rarity"
                                className="nav-links external"
                                onClick={handleClick}>
                                GEN-1 RARITY
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/raritygen2"
                                className="nav-links external"
                                onClick={handleClick}>
                                GEN-2 RARITY
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/home#about"
                                href="#about"
                                className="nav-links"
                                onClick={handleClick}>
                                ABOUT US
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/home#roadmap"

                                href="#roadmap"
                                className="nav-links"
                                onClick={handleClick}>
                                ROADMAP
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/home#launch"
                                href="#launch"
                                className="nav-links"
                                onClick={handleClick}>
                                GEN-2 LAUNCH
                            </Link>
                        </li>
                        <li className="nav-item">
                            <a href="https://twitter.com/CassetsNFTs"
                                to="/Home"

                                className="nav-links"
                                target="_blank" >
                                <i className="fa fa-lg fa-twitter"
                                    onClick={handleClick}></i>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://t.co/GcIvX08k1Q?amp=1"
                                to="/Home"
                                className="nav-links"
                                target="_blank" >
                                <i className="fab fa-discord"
                                    onClick={handleClick}></i>
                            </a>
                        </li>
                    </ul>
                    {/* <div className="nav-icon bg-white-300 px-5 rounded-lg" onClick={handleClick}>
                        <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
                    </div> */}
                    <div className="nav-icon p-2 rounded-full bg-white">
                    <Hamburger className="nav-icon w-full h-full" toggled={click} toggle={setClick} />
                    </div>
                </div>
            </nav>
        </>
    );
}
export default NavBar;