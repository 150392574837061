// import '../../styles/Home.css'
import home from "../../assets/main.png";
import song from "../../assets/flyaway.wav";
import Countdown from 'react-countdown';
import styled from "styled-components";
import '../../styles/Home.css';

const CounterText = styled.span`
margin-top: -1rem;`;

const Home = (props) => {
  return (
    <main className="max-h-full">
      <div className="min-h-screen relative w-full h-full flex flex-col justify-around bg-landingbg md:bg-cover bg-cover">
        <span id="home" className="offset-link"></span>
        {/* <div className="z-10 flex flex-col w-full mx-auto md:pl-28">
                    <img className="w-64 pt-20 md:pt-15 lg:pt-15 mx-auto md:mx-0" src={logo} style={{}}></img>
                </div> */}
        <div className="h-full w-full mx-auto flex flex-row justify-center ">
          <img src={home}></img>
        </div>

        {/* <div className="text-center font-face-ultra uppercase text-2xl md:text-4xl bg-white py-3 overflow-hidden w-full absolute bottom-0">
          <Countdown date={new Date(1639778400000)}
            renderer={renderCounter} />
        </div> */}
        {/* <div className="text-center">
          <button class="bg-mintcolor hover:bg-mintcolorDarker text-black font-bold py-2 px-4 rounded w-48 text-3xl font-face-ultra uppercase tracking-wider">
            <a href="https://cassets-batch1.cassetsnfts.com/">Mint</a>
          </button>
        </div> */}
        {/* <div className="relative max-h-full aspect-w-5 aspect-h-3 lg:aspect-w-10 lg:aspect-h-3 max-w-5xl w-2/3 lg:w-full self-center px-14 lg:mx-0 mb-20 mt-20 ">
                    <iframe className="
                    absolute inset-0 w-full h-full border-none bg-pink" src={preview} controls="1" autoplay="0" allowTransparency="true" frameborder="0" donotallowfullscreen></iframe>
                </div> */}
        <iframe
          src={song}
          allow="autoplay"
          style={{ display: "none" }}
          id="iframeAudio"
        ></iframe>
        <audio controls autoplay className="hidden">
          <source src={song} type="audio/wav" />
        </audio>
      </div>
    </main>
  );
};

const renderCounter = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <CounterText className="scroll-text">
      <div className="w-full" id="scroll-text">{hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds</div>
    </CounterText>
  );
};

export default Home;
