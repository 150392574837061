import React from 'react';
// import "../../styles/Rarity.css"

import background from "../../assets/backgrounds.png"
import cassettes from "../../assets/rarity_cassette.png"
import stickers from "../../assets/stickers.png"
import labels from "../../assets/labels.png"
import NavBar from '../commmon/Navbar';

function Rarity() {

    return (
        <div>
            <NavBar />
        <div className="overflow-hidden rarity-section text-black bg-white relative max-w-5xl w-full mx-auto font-face-ultra py-20 px-6 lg:px-0" id="rarity">
            <span id="rarity" className="absolute top-0 "></span>
            <div className="w-full border-b-2 leading-9 pb-4 md:pb-10 mt-20">
                <h1 className="uppercase text-center w-full font-face-ultra text-white text-3xl sm:text-4xl italic md:text-5xl lgmd:text-6xl lg:text-7xl" style={{ WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: 'black' }}>Rarity Chart</h1>
            </div>
            <div className="text-2xl md:text-3xl lg:text-5xl text-center pt-20">
                <h1 className="uppercase font-face-ultra text-2xl italic sm:text-3xl text-center md:text-4xl lg:text-5xl">Backgrounds</h1>
                <img className="backgrounds-image" src={background}></img>
            </div>
            <div className="text-2xl md:text-3xl lg:text-5xl text-center pt-5">
                <h1 className="pt-10 uppercase font-face-ultra text-2xl italic sm:text-3xl text-center md:text-4xl lg:text-5xl">Cassettes</h1>
                <img className="backgrounds-image" src={cassettes}></img>
            </div>
            <div className="text-2xl md:text-3xl lg:text-5xl text-center pt-5">
                <h1 className="pt-10 uppercase font-face-ultra text-2xl italic sm:text-3xl text-center md:text-4xl lg:text-5xl">Stickers</h1>
                <img className="backgrounds-image" src={stickers}></img>
            </div>
            <div className="text-2xl md:text-3xl lg:text-5xl text-center pt-5">
                <h1 className="pt-10 uppercase font-face-ultra text-2xl italic sm:text-3xl text-center md:text-4xl lg:text-5xl">Labels</h1>
                <img className="backgrounds-image" src={labels}></img>
            </div>
        </div>
        </div>
    );

}

export default Rarity;