import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import './App.css';

import store from './store'
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import NavBar from './components/commmon/Navbar';
import GalleryTabs from './components/GalleryTabs';
import HomePage from "./components/pages/HomePage";
import Startup from './components/commmon/Startup';
import Roadman from './components/pages/Roadman';
import Partnerships from './components/pages/Partnerships';
import Rare from './components/pages/Rare';
import Modal from './components/Modal'
import Rarity from './components/pages/Rarity';
import App from './App';
import Home from './Home';
import Rarity2 from './components/pages/Rarity-gen2';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <Startup/>
        <Routes>
          <Route  path='/' element={<HomePage/>} />
          <Route  path='/home' element={<HomePage/>} />
          <Route  path='/gallery' element={<GalleryTabs/>} />
          <Route  path='/rarity' element={<Rarity/>}/>
          <Route  path='/raritygen2' element={<Rarity2 />}/>
          {/* <Route exact path='/roadman' component={Roadman} />
          <Route exact path='/partneryard' component={Partnerships} />
          <Route exact path='/rare' component={Rare} /> */}
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();