import axios from 'axios';
import store from '../store';
import * as api from "./api";
import { CANDY_MACHINES } from '../consts/consts';

/**
 * Redux Action Types
 */
export const Types = {
    GET_TOKENS_ERROR: 'tokens/get_tokens_error',
    GET_TOKENS_REQUEST: 'tokens/get_tokens_request',
    GET_TOKENS_SUCCESS: 'tokens/get_tokens_success',
    GET_TOKENS_FILTER: 'tokens/get_tokens_filter',

    GET_ALL_TOKENS_REQUEST: "tokens/get_all_tokens_request",
    GET_ALL_TOKENS_SUCCESS_GEN_1: "tokens/get_all_tokens_success_gen1",
    GET_ALL_TOKENS_SUCCESS_GEN_2: "tokens/get_all_tokens_success_gen2",

    GET_ALL_TOKENS_ERROR: "tokens/get_all_tokens_error",

    CLEAR_TOKENS: 'tokens/clear_tokens'
};


export const getAllTokens = () => {

    return dispatch => {
        store.dispatch(getAllTokensRequest())
        try {
            api.getTokenMetaInfoCollection(CANDY_MACHINES.GEN_1_PNG, 1000).then(gen1 => {
                store.dispatch(getAllTokensSuccessGen1(gen1))
            })

            api.getTokenMetaInfoCollection(CANDY_MACHINES.GEN_2_AUDIO, 1000).then(gen2 => {
                store.dispatch(getAllTokensSuccessGen2(gen2))
            })
        } catch (error) {
            store.dispatch(getAllTokensError(error))
        }
    }
}

export const fetchTokens = (candyMachine) => {
    return dispatch => {
        //dispatch request call 
        store.dispatch(clearTokens())
        store.dispatch(getTokensRequest())
        //fetch data
        api.getTokenMetaInfoCollection(candyMachine, 20).then(res => {
            console.log(res)
            store.dispatch(getTokensSuccess(res));
        })

            //throw error if above fails 
            .catch(error => {
                store.dispatch(getTokensErrors(error))
            })
    }

}

export const fetchMoreTokens = (candyMachine) => {
    return dispatch => {
        //dispatch request call 
        store.dispatch(getTokensRequest())
        //fetch data
        api.getTokenMetaInfoCollectionPaginated(candyMachine).then(res => {
            console.log(res)
            store.dispatch(getTokensSuccess(res));
        })

            //throw error if above fails 
            .catch(error => {
                store.dispatch(getTokensErrors(error))
            })
    }

}

export const searchTokens = (candyMachine, searchPhrase) => {
    console.log(searchPhrase, "SEARCHING ACTIONS")
    return dispatch => {
        //dispatch request call 
        store.dispatch(clearTokens())
        store.dispatch(getTokensRequest())
        //fetch data
        let currentState = store.getState().tokenReducer
        let filteredTokens = []
        if (candyMachine == CANDY_MACHINES.GEN_1_PNG) {
            filteredTokens = currentState.allTokens.gen1.filter(e => e.tokens.arweave.name.includes(searchPhrase))
            console.log(filteredTokens)
        }
        else if (candyMachine == CANDY_MACHINES.GEN_2_AUDIO) {
            filteredTokens = currentState.allTokens.gen2.filter(e => e.tokens.arweave.name.includes(searchPhrase))
            console.log(filteredTokens)
        }
        store.dispatch(getTokensSuccess(filteredTokens))

        console.log(currentState)
        //throw error if above fails 

    }
}

export const getTokensRequest = (tokens) => ({
    type: Types.GET_TOKENS_REQUEST,
    payload: {
        tokens
    }
})

export const getTokensSuccess = (tokens) => ({
    type: Types.GET_TOKENS_SUCCESS,
    payload: {
        tokens
    }
})

export const getTokensErrors = (error) => ({
    type: Types.GET_TOKENS_ERROR,
    payload: {
        error
    }
});

export const clearTokens = () => ({
    type: Types.CLEAR_TOKENS,
})

export const getAllTokensRequest = () => ({
    type: Types.GET_ALL_TOKENS_REQUEST
})

export const getAllTokensSuccessGen1 = (gen1) => ({
    type: Types.GET_ALL_TOKENS_SUCCESS_GEN_1,
    payload: { gen1 }
})

export const getAllTokensSuccessGen2 = (gen2) => ({
    type: Types.GET_ALL_TOKENS_SUCCESS_GEN_2,
    payload: { gen2 }

})
export const getAllTokensError = (error) => ({
    type: Types.GET_ALL_TOKENS_ERROR,
    payload: {
        error
    }
})