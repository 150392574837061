import Home from './Home'
import About from "./About"
import Rarity from "./Rarity"
import Roadmap from "./RoadMap"
import Roadman from './Roadman'
import { connect } from 'react-redux'
import { getAllTokens } from '../../actions/tokens'
import { useEffect } from 'react'
import Partnerships from './Partnerships'
import Footer from './Footer'
import Rewards from './Rewards'
import NavBar from '../commmon/Navbar'

const HomePage = (props) => {
    return (
        <>
            <NavBar />
            <Home/>
            <About />
            <Roadman />
            <Rewards />
            <Footer />
        </>
    );
};

export default connect((tokenReducer) => (tokenReducer), {
    getAllTokens,
})(HomePage);